var windowEl = $(window);

function makeOtherFacilitiesInAreaMap() {
  (function ($) {
    if ($(".acf-map").length > 0) {
      var mapSelector = $(".acf-map");
      //icon for hf marker
      var icon =
        "https://henfieldstorage.co.uk/wp-content/themes/sage/dist/images/map-marker-sm.png";
      //static markers array for building markers query string
      var static_markers = [];
      //all lat/lng values to use for setting map centre bounds
      var all_coords = [];
      //loop through all the markers
      $(".marker[data-lng]").each(function (i) {
        //declare lat/lng
        var this_lat = $(this).data("lat");
        var this_lng = $(this).data("lng");
        //build qs for marker
        if (i === 0) {
          var icon =
            "https://henfieldstorage.co.uk/wp-content/themes/sage/dist/images/map-marker-sm.png";
        } else {
          var icon =
            "https://henfieldstorage.co.uk/wp-content/themes/sage/dist/images/map-marker-default.png";
        }
        var marker = "&markers=icon:" + icon + "|" + this_lat + "," + this_lng;
        //push marker qs values into array
        static_markers.push(marker);
        //push lat/lngs into array to determine which the max/min values are for bounds
        all_coords.push({
          lat: this_lat,
          lng: this_lng,
        });
      });

      //declare lat/lng
      var lat = all_coords.map(function (p) {
        return p.lat;
      });
      var lng = all_coords.map(function (p) {
        return p.lng;
      });

      //figure out minimum values
      var min_coords = {
        lat: Math.min.apply(null, lat),
        lng: Math.min.apply(null, lng),
      };

      //figure out max values
      var max_coords = {
        lat: Math.max.apply(null, lat),
        lng: Math.max.apply(null, lng),
      };

      //latitude: (maxLatOfAllPoints+minLatOfAllPoints)/2
      var bounded_centre_lat = (max_coords.lat + min_coords.lat) / 2;
      //longitude:(maxLngOfAllPoints+minLngOfAllPoints)/2
      var bounded_centre_lng = (max_coords.lng + min_coords.lng) / 2;

      //zoom level, set via acf on a per site basis, if not populated Google maps does a pretty good job of sorting zoom based on markers
      var static_zoom = mapSelector.data("static-zoom");

      //build query query
      var qsParams = {
        center: bounded_centre_lat + "," + bounded_centre_lng,
        zoom: static_zoom, //todo figure out correct zoom level for each store - maybe set data attribute for each static map per store
        size: "570x403",
        markers: static_markers.join(""),
        key: staticMapsData.googleMapsApiKey,
      };

      //static map maker
      makeStaticMap(qsParams, mapSelector);

      //make interactive other facilities map function
      function otherFacilitiesInteractiveMap(mapEl) {
        // Find marker elements within map.
        var $markers = mapEl.find(".marker");

        // Create gerenic map.
        var mapArgs = {
          zoom: mapEl.data("zoom") || 16,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        };
        var map = new google.maps.Map(mapEl[0], mapArgs);

        // Add markers.
        map.markers = [];
        $markers.each(function (index) {
          initMarker($(this), map, index);
        });

        // Center map based on markers.
        centerMap(map);

        // Return map instance.
        return map;
      }

      if (windowEl.width() < 960) {
        setTimeout(otherFacilitiesInteractiveMap, 5000, mapSelector);
      } else {
        mapSelector.one("mouseenter", function () {
          otherFacilitiesInteractiveMap($(this));
        });
      }
    }
  })(jQuery);
}

function makeLondonStoresMap() {
  (function ($) {
    if ($(".london-stores__map").length > 0) {
      function londonStoresInteractiveMap(mapEl) {
        // Find marker elements within map.
        var $markers = mapEl.find(".marker");

        // Create gerenic map.
        var mapArgs = {
          zoom: mapEl.data("zoom") || 16,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
        };
        var map = new google.maps.Map(mapEl[0], mapArgs);

        // Add markers.
        map.markers = [];
        $markers.each(function (index) {
          initMarker($(this), map, index, true);
        });

        // Center map based on markers.
        centerMap(map);

        // call the places ready function - used for place autocomplete
        placesReady();

        // Return map instance.
        return map;
      }

      mapSelector = $(".london-stores__map");

      if (windowEl.width() < 960) {
        setTimeout(londonStoresInteractiveMap, 5000, mapSelector);
      } else {
        mapSelector.one("mouseenter", function () {
          londonStoresInteractiveMap($(this));
        });
      }
    }
  })(jQuery);
}

function initMarker($marker, map, index, hf_only = false) {
  // Get position from marker.
  var lat = $marker.data("lat");
  var lng = $marker.data("lng");
  var latLng = {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };

  // set icon base
  var iconBase = "/wp-content/themes/sage/dist/images/";

  // create the marker object
  var markerObj = {
    position: latLng,
    map: map,
  };

  // if the first marker, add custom marker
  if (index === 0 || hf_only) {
    markerObj.icon = iconBase + "map-marker-sm.png";
  } else {
    markerObj.icon = iconBase + "map-marker-default.png";
  }

  // Create marker instance.
  var marker = new google.maps.Marker(markerObj);

  // Append to reference for later use.
  map.markers.push(marker);

  // If marker contains HTML, add it to an infoWindow.
  /* disabled
  if( $marker.html() ){

    // Create info window.
    var infowindow = new google.maps.InfoWindow({
      content: $marker.html()
    });

    // if first marker, open infowindow - disabled as it prevents bounds from working on small screens
    // if(index === 0) infowindow.open(map, marker);

    // Show info window when marker is clicked.
    google.maps.event.addListener(marker, 'click', function() {
      infowindow.open( map, marker );
    });
  }
  */
}

function centerMap(map) {
  // Create map boundaries from all map markers.
  var bounds = new google.maps.LatLngBounds();
  map.markers.forEach(function (marker) {
    bounds.extend({
      lat: marker.position.lat(),
      lng: marker.position.lng(),
    });
  });
  // Case: Single marker.
  if (map.markers.length == 1) {
    map.setCenter(bounds.getCenter());
    // Case: Multiple markers.
  } else {
    map.fitBounds(bounds);
  }
}

function createMap(el, center, zoom) {
  var branches = allBranches;
  var styles = [
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [{ color: "#444444" }],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [{ color: "#f2f2f2" }],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "poi.business",
      elementType: "geometry.fill",
      stylers: [{ visibility: "on" }],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [{ saturation: -100 }, { lightness: 45 }],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [{ visibility: "simplified" }],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [{ color: "#b4d4e1" }, { visibility: "on" }],
    },
  ];
  //console.log(branches);
  // TODO - use bounding box to ensure all points are in view

  // Create a map object and specify the DOM element for display.
  var map = new google.maps.Map(el, {
    center: center,
    scrollwheel: false,
    zoom: zoom,
    styles: styles,
    mapTypeControl: false,
    streetViewControl: false,
  });

  var icon = {
    url: assetUri + "/images/map-marker.png", // url
    scaledSize: new google.maps.Size(60, 76), // scaled size
    origin: new google.maps.Point(0, 0), // origin
    anchor: new google.maps.Point(30, 76), // anchor
  };

  for (var i = 0; i < branches.length; i++) {
    var branch = branches[i];
    marker = new google.maps.Marker({
      position: { lat: parseFloat(branch[1]), lng: parseFloat(branch[2]) },
      map: map,
      icon: icon,
      title: branch[0],
    });

    // Attach click event to the marker.
    (function (marker, branch) {
      google.maps.event.addListener(marker, "click", function (e) {
        // console.log(branch);
        window.location.href = branch[3];
      });
    })(marker, branch);
  }

  return map;
}

//query string maker function
function objectToQueryString(obj) {
  //build query string without converting to html entities
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(p + "=" + obj[p]);
    }
  return str.join("&");
}

function makeStaticMap(qsParams, mapSelector) {
  if (qsParams !== undefined && mapSelector !== undefined) {
    //additional style params
    var styles =
      "&style=feature:administrative|element:labels.text.fill|color:444444&style=feature:landscape|element:all|color:f2f2f2&style=feature:poi|element:all|visibility:off&style=feature:poi.business|element:geometry.fill|visibility:on&style=feature:road|element:all|saturation:-100|lightness:45&style=feature:road.highway|element:all|visibility:simplified&style=feature:road.arterial|element:labels.icon|visibility:off&style=feature:transit|element:all|visibility:off&style=feature:water|element:all|color:b4d4e1|visibility:on";
    //maps base uri
    var googleStaticMapsBaseUri =
      "https://maps.googleapis.com/maps/api/staticmap?";
    //full map url
    var mapUrl =
      googleStaticMapsBaseUri + objectToQueryString(qsParams) + styles;
    //console.log(mapUrl);
    //make map container have static map background
    mapSelector.css({
      "background-image": "url(" + mapUrl + ")",
      "background-size": "cover",
      "background-position": "center center",
      "background-repeat": "no-repeat",
    });
  } else {
    return false;
  }
}

//make interactive map function
function makeStoresInteractiveMap(map, with_search = false) {
  center = { lat: 51.27, lng: -0.15 };
  zoom = 9; // TODO - map can only have whole number of zoom, consider clustering or smaller pins
  map = createMap(map, center, zoom);

  if (with_search) {
    const location_search = document.querySelector('[name="location-search"]');
    const result = document.querySelector(".stores-map-search__result");
    let initial_shift = false;
    map.addListener("idle", () => {
      if (!initial_shift) {
        map.panBy(-(window.innerWidth / 4), 0);
        initial_shift = true;
      }
    });
    if (location_search) {
      const options = {
        geocode: true,
        componentRestrictions: { country: "uk" },
      };
      const autocomplete = new google.maps.places.Autocomplete(
        location_search,
        options
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();
        result.classList.remove("show");

        if (Object.keys(place).length > 1) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();

          const postcodeLookupObj = JSON.parse(siteData);
          let closestSite = findClosestSite(
            lat,
            lng,
            postcodeLookupObj.sites,
            true
          );

          closestSite = allBranches.find(
            (e) => e[0].toLowerCase() === closestSite.toLowerCase()
          );
          map.setCenter({
            lat: parseFloat(closestSite[1]),
            lng: parseFloat(closestSite[2]),
          });
          map.setZoom(14);
          map.panBy(-(window.innerWidth / 4), 0);
          result.innerHTML = `Your closest store is <a href="${closestSite[3]}">${closestSite[0]}</a>`;
          result.classList.add("show");
        }
      });
    }
  }
}
function makeSiteInteractiveMap(map, lat = false, lng = false) {
  center = {
    lat: parseFloat(allBranches[0][1]),
    lng: parseFloat(allBranches[0][2]),
  };
  if (lat) center.lat = lat;
  if (lng) center.lng = lng;

  zoom = document.body.classList.contains("postid-14615") ? 12 : 14;
  if (map instanceof jQuery) {
    for (let mapTarget of map) {
      createMap(mapTarget, center, zoom);
    }
  } else {
    createMap(map, center, zoom);
  }
}

// promise function for google maps on find a store page
function makeGoogleMap() {
  (function ($) {
    var mapTargets = false;
    var center;
    var zoom;
    var icon =
      "https://henfieldstorage.co.uk/wp-content/themes/sage/dist/images/map-marker-sm.png";
    var qsParams;
    var branch;
    var mapSelector;

    if ($("#stores-map").length > 0) {
      mapSelector = $("#stores-map");

      if (windowEl.width() < 960) {
        setTimeout(makeStoresInteractiveMap, 5000, mapSelector[0]);
      } else {
        mapSelector.one("mouseenter", function () {
          makeStoresInteractiveMap(this);
        });
      }
    } else if (document.querySelector(".stores-map-search")) {
      const map_container = document.querySelector(".stores-map-search");
      const map = map_container.querySelector(".stores-map");

      if (windowEl.width() < 960) {
        setTimeout(makeStoresInteractiveMap, 1000, map, true);
      } else {
        map_container.addEventListener(
          "mouseenter",
          function () {
            makeStoresInteractiveMap(this.querySelector(".stores-map"), true);
          },
          { once: true }
        );
      }
    } else if ($(".single-store-map").length > 0) {
      let lat, lng;

      //map selector
      mapSelector = $(".single-store-map");

      // if data-store exists
      if (mapSelector.data("store")) {
        branch = allBranches.find(
          (e) => e[0].toLowerCase() === mapSelector.data("store").toLowerCase()
        );
        lat = branch[1];
        lng = branch[2];
      } else {
        lat = allBranches[0][1];
        lng = allBranches[0][2];
      }
      lat = parseFloat(lat);
      lng = parseFloat(lng);

      //map icon //for prod staticMapsData.siteUrl
      //var icon =  'https://henfieldstorage.co.uk/wp-content/themes/sage/dist/images/map-marker-sm.png';
      //qs params
      qsParams = {
        center: lat + "," + lng,
        zoom: 14,
        size: "570x340",
        markers: "icon:" + icon + "|" + lat + "," + lng,
        key: staticMapsData.googleMapsApiKey,
      };
      makeStaticMap(qsParams, mapSelector);

      if (windowEl.width() < 960) {
        setTimeout(makeSiteInteractiveMap, 5000, mapSelector, lat, lng);
      } else {
        mapSelector.one("mouseenter", function () {
          makeSiteInteractiveMap(this, lat, lng);
        });
      }
    } else {
      return false;
    }
  })(jQuery);

  placesReady(); // call the places ready function - used for place autocomplete
  makeOtherFacilitiesInAreaMap(); //added to single sites
}
